import { render, staticRenderFns } from "./SloganModule.vue?vue&type=template&id=1e12bf12&scoped=true&"
import script from "./SloganModule.vue?vue&type=script&lang=js&"
export * from "./SloganModule.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e12bf12",
  null
  
)

export default component.exports
<template>
    <slogan-module-component/>
</template>

<script>
    import SloganModuleComponent from "@/components/admin/SloganModuleComponent";

    export default {
        name: "SloganModule",
        title: "Slogan | Private",
        components: { SloganModuleComponent }
    }
</script>

<style scoped>

</style>